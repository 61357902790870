import i18n from "i18next";

const AboutUsVideo = () => {
    const locale = i18n.language;

    return (
        <div className="container max-w-screen-md mx-auto py-10 px-4">
            <div className=" relative pb-[52%]">
                {(locale === 'en' || locale === 'it') &&
                    <iframe className="mx-auto w-full absolute h-full"
                            src="https://www.youtube.com/embed/npS_Rh51sjE?si=Qdv73RX_UmUohunk"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>}
                {locale === 'fr' &&
                    <iframe className="mx-auto w-full absolute h-full"
                            src="https://www.youtube.com/embed/ugj05tyfo9M?si=rToiRpZKzkBw27Bu"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>}
                {locale === 'de' &&
                    <iframe className="mx-auto w-full absolute h-full"
                            src="https://www.youtube.com/embed/j38_hV6tdvU?si=gePsONtV4ePYXllF"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>}
            </div>
        </div>)

}
export default AboutUsVideo;