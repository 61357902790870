import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const GetInTouch = () => {
    const svg1 = <svg xmlns="http://www.w3.org/2000/svg" width="316" height="244" className="absolute -top-[120px]"
                      viewBox="0 0 316 244" fill="none">
        <rect y="172.58" width="244.065" height="100" rx="50" transform="rotate(-45 0 172.58)" fill="white"
              fillOpacity="0.25"/>
        <rect x="168" y="172.58" width="142.746" height="65.6199" rx="32.8099" transform="rotate(-45 168 172.58)"
              fill="white" fillOpacity="0.25"/>
    </svg>;
    const svg2 = <svg xmlns="http://www.w3.org/2000/svg" width="23" height="8" viewBox="0 0 23 8" fill="none">
        <path d="M22.3536 4.35355C22.5488 4.15829 22.5488 3.84171 22.3536 3.64645L19.1716 0.464466C18.9763 0.269204 18.6597 0.269204 18.4645 0.464466C18.2692 0.659728 18.2692 0.976311 18.4645 1.17157L21.2929 4L18.4645 6.82843C18.2692 7.02369 18.2692 7.34027 18.4645 7.53553C18.6597 7.7308 18.9763 7.7308 19.1716 7.53553L22.3536 4.35355ZM0 4.5H22V3.5H0V4.5Z"
              fill="white"/>
    </svg>;
    const {t}= useTranslation();

    return <>
        <section className="bg-[#E0F7FE] my-10">
            <div className="from-bottom px-11 container max-w-screen-lg mx-auto rounded-3xl bg-[#3D8BD2] text-white min-h-[192px] flex flex-col justify-around relative overflow-hidden">
                {svg1}
                <span className="font-black text-3xl block text-center">
                    {t('mainContent.getInTouch')}
                </span>
                <Link className="capitalize" to="/contact">
                    <button className="uppercase flex gap-4 items-center border border-white rounded-full px-5  text-xl font-light ml-auto">
                        {t('navigation.contactUs')}
                        {svg2}
                    </button>
                </Link>
            </div>
        </section>
    </>
}
export default GetInTouch;