import React, {useEffect} from "react";
import Employe from "./images/employe.jpg";
import {useTranslation} from "react-i18next";

const ImageSlider = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const parallaxScroll = () => {
            const scrollY = window.scrollY;
            const background = document.querySelector(".parallax-background");

            const speed = 0.5;
            background.style.backgroundPositionY = -scrollY * speed + "px";
        };

        window.addEventListener("scroll", parallaxScroll);

        return () => {
            window.removeEventListener("scroll", parallaxScroll);
        };
    }, []);

    return (
        <section className="parallax-section">
            <div
                className="parallax-background h-[40vw] min-h-[300px] relative bg-contain"
                style={{
                    backgroundImage: `url(${Employe})`,
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center 0px",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="flex justify-between absolute bottom-1/3 w-[104%] -left-[2%]">
                    <a href="#section2">
                        <button className="outline-[12px] text-[#3D8BD2] font-bold outline outline-neutral-700/20 rounded-xl bg-[#E0F7FE] px-10 py-2">{t('navigation.candidate')}</button>
                    </a>
                    <a href="#section1">
                        <button className="outline-[12px] text-[#E0F7FE] font-bold outline outline-neutral-700/20 rounded-xl bg-[#3D8BD2] px-10 py-2">{t('navigation.companies')}</button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ImageSlider;
