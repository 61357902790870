import defaultBg from "./../../images/analyses-bg.jpg";


const FeaturedImage = ({bg, title}) => {
    return <>
        <div className="mt-[68px] h-[20vw] flex items-center justify-center bg-no-repeat" style={{background: `url(${bg ?? defaultBg})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
            {title && <p className="bg-white/70 text-neutral-700 font-black capitalize text-4xl px-4 py-2 rounded shadow-lg">{title}</p>}
        </div>

    </>
}

export default FeaturedImage