import FeaturedImage from "../components/generic/FeaturedImage";
import SimpleQuote from "../components/generic/SimpleQuote";
import GetInTouch from "../GetInTouch";
import PDFContainer from "../components/generic/PDFContainer";
import {useTranslation} from "react-i18next";
import en1 from './../PDFs/en1.pdf';
import en2 from './../PDFs/en2.pdf';
import en4 from './../PDFs/en4.pdf';
import en3 from './../PDFs/en3.pdf';
import de1 from './../PDFs/de1.pdf';
import de2 from './../PDFs/de2.pdf';
import de4 from './../PDFs/de4.pdf';
import de3 from './../PDFs/de3.pdf';
import fr1 from './../PDFs/fr1.pdf';
import fr3 from './../PDFs/fr3.pdf';
import fr4 from './../PDFs/fr4.pdf';
import it4 from './../PDFs/it4.pdf';
import it3 from './../PDFs/it3.pdf';
import i18n from "i18next";
import {useEffect, useState} from "react";

const NewOurAnalyses = () => {
    const {t} = useTranslation();
    const locale = i18n.language;
    const [file1, setFile1] = useState(en1);
    const [file2, setFile2] = useState(en2);
    const [file3, setFile3] = useState(en3);
    const [file4, setFile4] = useState(en4);

    useEffect(() => {
        switch (locale) {
            case 'en':
                setFile1(en1);
                setFile2(en2);
                setFile3(en3);
                setFile4(en4);
                break;
            case 'fr':
                setFile1(fr1);
                setFile2(en2);
                setFile3(fr3);
                setFile4(fr4);
                break;
            case 'de':
                setFile1(de1);
                setFile2(de2);
                setFile3(de3);
                setFile4(de4);
                break;
            case 'it':
                setFile1(en1);
                setFile2(en2);
                setFile3(it3);
                setFile4(it4);
                break;
            default:
                setFile1(en1);
                setFile2(en2);
                setFile3(en3);
                setFile4(en4);
        }
    }, [locale]);

    return (
        <div className="">
            <FeaturedImage title={t("ourAnalysesNewPage.header")}/>
            <SimpleQuote title={t("ourAnalysesNewPage.quote.title")} writer={'Tino Senoner'}
                         quote={t("ourAnalysesNewPage.quote.content")}/>
            <PDFContainer date={"01.03.2024"} title={t("ourAnalysesNewPage.pdfComponent.title4")}
                          text={t("ourAnalysesNewPage.pdfComponent.description4")}
                          file={file4}
                          fileName={t("ourAnalysesNewPage.pdfComponent.fileName4")}
            />
            <PDFContainer date={"08.01.2024"} title={t("ourAnalysesNewPage.pdfComponent.title3")}
                          text={t("ourAnalysesNewPage.pdfComponent.description3")}
                          file={file3}
                          fileName={t("ourAnalysesNewPage.pdfComponent.fileName3")}
            />
            <PDFContainer date={"21.12.2022"} title={t("ourAnalysesNewPage.pdfComponent.title1")}
                          text={t("ourAnalysesNewPage.pdfComponent.description1")}
                          file={file1}
                          fileName={t("ourAnalysesNewPage.pdfComponent.fileName1")}
            />
            <PDFContainer date={"09.01.2022"} title={t("ourAnalysesNewPage.pdfComponent.title2")}
                          text={t("ourAnalysesNewPage.pdfComponent.description2")} file={file2}
                          fileName={t("ourAnalysesNewPage.pdfComponent.fileName2")}
            />

            <GetInTouch/>
        </div>
    )
}
export default NewOurAnalyses