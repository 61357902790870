import Logo from "./images/logo.png";
import {useTranslation} from "react-i18next";
import TranslationSwitcher from "./TranslationSwitcher";
import {Link} from "react-router-dom";
import {useState} from "react";
import {twMerge} from "tailwind-merge";

const Navbar = () => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const closeMenu = () => {
        setIsOpen(false);
    };
    return <nav
        className="flex w-screen justify-between text-neutral-700 fixed left-0 right-0 backdrop-blur-sm bg-gradient-to-b from-white top-0 z-20">
        <div
            className="flex flex-col md:flex-row w-full items-center justify-between px-5 py-2 xl:px-12 container mx-auto">
            <div className="shrink-0 w-full md:w-auto flex justify-between">
                <Link to="/" className="self-start" onClick={closeMenu}>
                    <img src={Logo} alt="logo" className="h-10"/>
                </Link>

                <span className="text-sky-600 cursor-pointer flex items-center md:hidden">
                    {!isOpen &&
                        <button onClick={() => setIsOpen(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                 className="bi bi-list w-10" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                            </svg>
                        </button>}
                    {isOpen &&
                        <button onClick={() => setIsOpen(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x-lg w-10"
                                 viewBox="0 0 16 16">
                                <path
                                    d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                            </svg>
                        </button>}
                </span>
            </div>

            <div
                className={twMerge("flex flex-col-reverse md:flex-row w-full items-center justify-end px-5 py-2 xl:px-12 container mx-auto md:flex", !isOpen && "hidden md:flex")}>
                <ul className="font-heading flex flex-col md:flex-row space-x-2.5 px-4 font-semibold md:flex items-center">
                    <li className="border-b-4 border-transparent p-2 delay-150 duration-300 ease-in-out hover:text-blue-700">
                        <Link className="capitalize" to="/" onClick={closeMenu}>{t('navigation.home')}</Link>
                    </li>
                    <li className=" border-b-4 border-transparent p-2 delay-150 duration-300 ease-in-out hover:text-blue-700">
                        <Link className="capitalize" to="/about-us" onClick={closeMenu}>{t('navigation.aboutUs')}</Link>
                    </li>
                    <li className=" border-b-4 border-transparent p-2 delay-150 duration-300 ease-in-out hover:text-blue-700">
                        <Link className="capitalize" to="/our-analyses" onClick={closeMenu}>{t('navigation.ourAnalysis')}</Link>
                    </li>
                    <li className="border-b-4 border-transparent p-2 delay-150 duration-300 ease-in-out hover:text-blue-700">
                        <Link className="capitalize" to="/contact" onClick={closeMenu}>{t('navigation.contactUs')}</Link>
                    </li>
                    <li className="border-b-4 border-transparent p-2 delay-150 duration-300 ease-in-out hover:text-blue-700">
                        <a href="https://n1.dynaskills.com/auth/login" target="_blank" rel="noreferrer" onClick={closeMenu}>
                            <button
                                className="flex items-center gap-2 rounded bg-primaryBlue hover:bg-sky-700 text-white delay-150 duration-300 ease-in-out px-4 py-1 drop-shadow-md">
                                <span>{t('navigation.connect')}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd"
                                          d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
                                    <path fillRule="evenodd"
                                          d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                </svg>
                            </button>
                        </a>
                    </li>
                </ul>
                <TranslationSwitcher/>
            </div>
        </div>
    </nav>
}
export default Navbar