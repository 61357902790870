import Quote from "./Quote";
import GetInTouch from "./GetInTouch";
import Section1 from "./Section1";
import Section2 from "./Section2";
import ImageSlider from "./ImageSlider";
import LinksSection2 from "./LinksSection2";

const HomePage = () => {
    return <div className="overflow-x-hidden relative">
        <ImageSlider/>
        <Quote/>
        <LinksSection2/>
        <GetInTouch/>
        <Section1/>
        <span className="h-px w-full max-w-[80vw] bg-neutral-200 mx-auto block md:hidden"></span>
        <Section2/>
    </div>
}
export default HomePage;