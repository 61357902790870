import Puzzle from "./images/puzzle.png";
import {useTranslation} from "react-i18next";
const Quote = () => {
    const { t } = useTranslation();

    return <>
        <section className="container mx-auto py-10 px-4 overflow-hidden">
            <div className="relative text-center text-neutral-700 font-bold text-4xl w-min mx-auto flex from-bottom">
                <span className="z-10 relative">Upskilling</span>
                <img src={Puzzle} className="-ml-10" alt=""/>
            </div>

            <figure className="max-w-screen-md mx-auto text-center from-bottom">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
                    <path
                        d="M16.8753 16.875C17.3726 16.875 17.8495 16.6775 18.2012 16.3258C18.5528 15.9742 18.7503 15.4973 18.7503 15V10.4212C18.7503 9.92397 18.5528 9.44706 18.2012 9.09542C17.8495 8.74379 17.3726 8.54625 16.8753 8.54625H14.2728C14.2728 7.88813 14.3122 7.22813 14.3891 6.57C14.5053 5.8725 14.7003 5.25187 14.9703 4.71C15.2422 4.16625 15.591 3.74062 16.0185 3.42937C16.4441 3.08063 16.986 2.90625 17.646 2.90625V0C16.5603 0 15.6116 0.2325 14.7959 0.6975C13.9874 1.15779 13.2923 1.79335 12.7616 2.5575C12.2289 3.39996 11.8363 4.32318 11.5991 5.29125C11.36 6.35955 11.243 7.45155 11.2503 8.54625V15C11.2503 15.4973 11.4479 15.9742 11.7995 16.3258C12.1511 16.6775 12.628 16.875 13.1253 16.875H16.8753ZM5.62533 16.875C6.12261 16.875 6.59952 16.6775 6.95115 16.3258C7.30278 15.9742 7.50033 15.4973 7.50033 15V10.4212C7.50033 9.92397 7.30278 9.44706 6.95115 9.09542C6.59952 8.74379 6.12261 8.54625 5.62533 8.54625H3.02283C3.02283 7.88813 3.0622 7.22813 3.13907 6.57C3.25532 5.8725 3.45033 5.25187 3.72033 4.71C3.9922 4.16625 4.34095 3.74062 4.76845 3.42937C5.19408 3.08063 5.73595 2.90625 6.39595 2.90625V0C5.31032 0 4.36158 0.2325 3.54595 0.6975C2.73742 1.15779 2.04228 1.79335 1.51157 2.5575C0.978935 3.39996 0.586346 4.32318 0.349075 5.29125C0.109986 6.35955 -0.00701358 7.45155 0.000324897 8.54625V15C0.000324897 15.4973 0.197869 15.9742 0.5495 16.3258C0.90113 16.6775 1.37804 16.875 1.87533 16.875H5.62533Z"
                        fill="#3D8BD2"/>
                </svg>
                <blockquote>
                    <p className="text-2xl italic font-medium text-neutral-700">
                        {t('mainContent.intro')}
                    </p>
                </blockquote>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none"
                     className="ml-auto rotate-180">
                    <path
                        d="M16.8753 16.875C17.3726 16.875 17.8495 16.6775 18.2012 16.3258C18.5528 15.9742 18.7503 15.4973 18.7503 15V10.4212C18.7503 9.92397 18.5528 9.44705 18.2012 9.09542C17.8495 8.74379 17.3726 8.54625 16.8753 8.54625H14.2728C14.2728 7.88812 14.3122 7.22813 14.3891 6.57C14.5053 5.8725 14.7003 5.25188 14.9703 4.71C15.2422 4.16625 15.591 3.74062 16.0185 3.42937C16.4441 3.08063 16.986 2.90625 17.646 2.90625V0C16.5603 0 15.6116 0.2325 14.7959 0.6975C13.9874 1.15779 13.2923 1.79335 12.7616 2.5575C12.2289 3.39996 11.8363 4.32318 11.5991 5.29125C11.36 6.35955 11.243 7.45155 11.2503 8.54625V15C11.2503 15.4973 11.4479 15.9742 11.7995 16.3258C12.1511 16.6775 12.628 16.875 13.1253 16.875H16.8753ZM5.62533 16.875C6.12261 16.875 6.59952 16.6775 6.95115 16.3258C7.30278 15.9742 7.50033 15.4973 7.50033 15V10.4212C7.50033 9.92397 7.30278 9.44705 6.95115 9.09542C6.59952 8.74379 6.12261 8.54625 5.62533 8.54625H3.02283C3.02283 7.88812 3.0622 7.22813 3.13907 6.57C3.25532 5.8725 3.45033 5.25188 3.72033 4.71C3.9922 4.16625 4.34095 3.74062 4.76845 3.42937C5.19408 3.08063 5.73595 2.90625 6.39595 2.90625V0C5.31033 0 4.36158 0.2325 3.54595 0.6975C2.73742 1.15779 2.04228 1.79335 1.51157 2.5575C0.978935 3.39996 0.586346 4.32318 0.349075 5.29125C0.109986 6.35955 -0.00701358 7.45155 0.000324897 8.54625V15C0.000324897 15.4973 0.197869 15.9742 0.5495 16.3258C0.90113 16.6775 1.37804 16.875 1.87533 16.875H5.62533Z"
                        fill="#72BBCE"/>
                </svg>
                <figcaption className="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
                    <div
                        className="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500 dark:divide-gray-700">
                        <cite className="pe-3 font-medium text-neutral-700">Tino Senoner</cite>
                    </div>
                </figcaption>
            </figure>
        </section>
    </>
}
export default Quote;