import {Trans, useTranslation} from "react-i18next";

const LinksSection2 = () => {
    const {t} = useTranslation();

    return (<section className="relative">
        <span className="absolute h-4/5 bottom-0 w-40 bg-[#E0F7FE] rounded-l-3xl right-1/2 z-0"></span>
        <span className="absolute h-4/5 left-1/2 w-40 bg-[#3D8BD280] top-0 rounded-r-3xl z-0"></span>

        <div className="container mx-auto py-10 text-neutral-700 relative ">
            <div className="flex gap-4 max-w-screen-md mx-auto flex-col md:flex-row px-4 items-center">
                <div className="flex flex-col gap-4 max-w-[375px]">
                    <a href="#section2" className="order-2 md:order-1 from-left group">
                        <div
                            className=" cursor-pointer h-[225px] rounded-[50px] overflow-hidden rounded-bl-none bg-[url('block6.png')] bg-cover bg-no-repeat pt-8 pr-2">
                            <div className="flex justify-end">
                                <button
                                    className="text-lg font-bold group-hover:text-white text-[#3D8BD2] delay-150 duration-300 ease-in-out group-hover:bg-sky-400 bg-[#E0F7FE] rounded-full border-white border-4 px-4 hover:bg-sky-800">
                                    {t('mainContent.training.header')}
                                </button>
                            </div>
                            <div className="flex gap-x-4 mt-5">
                        <span
                            className="h-[200px] w-10 bg-[#E0F7FE] block delay-150 duration-300 ease-in-out group-hover:bg-sky-400"></span>
                                <span className="text-white text-xl font-black shadow-2xl mt-9"
                                >{t('mainContent.training.dynaskillsTrainings')}</span></div>
                        </div>
                    </a>
                    <a href="#section2" className="order-3 md:order-2 from-left group">
                        <div
                            className=" cursor-pointer h-[225px] rounded-[50px] overflow-hidden rounded-bl-none bg-[url('block1.png')] bg-cover bg-no-repeat pt-8 pr-2">
                            <div className="flex justify-end">
                                <button
                                    className="text-lg font-bold group-hover:text-white text-[#3D8BD2] delay-150 duration-300 ease-in-out group-hover:bg-sky-400 bg-[#E0F7FE] rounded-full border-white border-4 px-4 hover:bg-sky-800">
                                    {t('mainContent.training.platform')}
                                </button>
                            </div>
                            <div className="flex gap-x-4 mt-5">
                        <span
                            className="h-[200px] w-10 bg-[#E0F7FE] block delay-150 duration-300 ease-in-out group-hover:bg-sky-400"></span>
                                <span className="text-white text-xl font-black shadow-2xl mt-9"
                                >{t('mainContent.training.platformDynaskills')}</span></div>
                        </div>
                    </a>
                    <div
                        className="order-1 md:order-3 from-left h-[225px] rounded-3xl rounded-bl-none p-4 flex flex-col justify-around relative">
                        <p className="text-3xl leading-[20px] font-extralight">{t('mainContent.upskilling')}<br/>
                            <span className="font-bold text-4xl"> {t('mainContent.candidate')}</span></p>
                        <p className="text-md">
                            <Trans i18nKey="mainContent.upskillingCandidate" components={{strong: <strong/>}}/></p>
                    </div>
                </div>

                <div className="flex flex-col gap-4 max-w-[375px]">
                    <div
                        className="from-right h-[225px] rounded-3xl rounded-br-none text-right p-4 flex flex-col justify-around relative">
                        <p className="text-3xl leading-[20px] font-extralight">{t('mainContent.upskilling')}<br/>
                            <span className="font-bold text-4xl">{t('mainContent.companies')}</span></p>
                        <p className="text-md">
                            <Trans i18nKey="mainContent.upskillingCompanies" components={{strong: <strong/>}}/></p>
                    </div>
                    <a href="#section1"
                       className="from-right group">
                        <div
                            className=" cursor-pointer h-[225px] rounded-[50px] overflow-hidden rounded-br-none bg-[url('block2.png')] pt-8">
                            <div className="flex justify-end pr-2">
                                <button
                                    className="text-lg font-bold text-white delay-150 duration-300 ease-in-out group-hover:bg-sky-400 bg-sky-700 rounded-full border-white border-4 px-4 hover:bg-sky-800">
                                    {t('mainContent.training.platform')}
                                </button>
                            </div>
                            <div className="flex gap-x-4 mt-5 justify-between pl-10">
                                <span
                                    className="text-white text-xl font-black shadow-2xl mt-9">{t('mainContent.training.platformDynajobs')}</span>
                                <span
                                    className="h-[200px] w-10 bg-sky-700 block delay-150 duration-300 ease-in-out group-hover:bg-sky-400"></span>
                            </div>
                        </div>
                    </a>

                    <a href="#section1"
                       className="from-right group">
                        <div
                            className=" cursor-pointer h-[225px] rounded-[50px] overflow-hidden rounded-br-none bg-[url('block4.png')] pt-8">
                            <div className="flex justify-end pr-2">
                                <button
                                    className="text-lg font-bold text-white delay-150 duration-300 ease-in-out group-hover:bg-sky-400 bg-sky-700 rounded-full border-white border-4 px-4 hover:bg-sky-800">
                                    {t('mainContent.training.services')}
                                </button>
                            </div>
                            <div className="flex gap-x-4 mt-5 justify-between pl-10">
                                <span className="text-white text-xl font-black shadow-2xl mt-9"
                                    >{t('mainContent.training.servicesDynajobs')}</span>
                                <span
                                    className="h-[200px] w-10 bg-sky-700 block delay-150 duration-300 ease-in-out group-hover:bg-sky-400"></span>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </section>);
}
export default LinksSection2;