import {useTranslation} from "react-i18next";
import {useState} from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import {ReactComponent as EmailSvg} from '../images/svg/envelope.svg';
import {ReactComponent as PhoneSvg} from '../images/svg/telephone.svg';
import {ReactComponent as PersonSvg} from '../images/svg/person.svg';
import {ReactComponent as SubjectSvg} from '../images/svg/chat-right-text.svg';

const ContactForm = () => {
    const {t} = useTranslation();
    const [senderFullName, setSenderFullName] = useState("");
    const [senderSubject, setSenderSubject] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderEmail, setSenderEmail] = useState("");
    const [messageClient, setMessageClient] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const handleSubmit = () => {
        setLoading(true);
        setError("");

        if (
            senderEmail !== "" &&
            senderFullName !== "" &&
            senderPhone !== "" &&
            senderSubject !== "" &&
            messageClient !== ""
        ) {
            emailjs
                .send(
                    "service_mk916rn",
                    "template_5ifw0ck",
                    {
                        from_name: senderFullName,
                        from_email: senderEmail,
                        from_phone: senderPhone,
                        from_subject: senderSubject,
                        message: messageClient,
                    },
                    "7pan3UyznhLH6JQvS"
                )
                .then(() => {
                    setLoading(false);
                    swal("Sent!", "Your message was sent successfully!", "success").then(
                        () => {
                            setSenderFullName("");
                            setSenderPhone("");
                            setSenderEmail("");
                            setSenderSubject("");
                            setMessageClient("");
                        }
                    );
                })
                .catch((err) => console.dir(err));
        } else {
            setLoading(false);
            setError("Oups!, Empty fields are not allowed! 🤕");
        }
    };
    return <form className="bg-white shadow-lg rounded px-4 py-8 max-w-md mx-auto flex flex-col gap-4">
        <label htmlFor="" className="relative">
            <input
                className="shadow appearance-none border rounded w-full py-2 pl-8 pr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text" placeholder={t('formFields.fullName')}
                value={senderFullName} onChange={(e) => setSenderFullName(e.target.value)}/>
            <PersonSvg className="absolute top-1/2 transform -translate-y-1/2 left-2 text-primaryBlue"/>
        </label>
        <label htmlFor="" className="relative">
            <input
                className="shadow appearance-none border rounded w-full py-2 pl-8 pr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="email" placeholder={t('formFields.email')}
                value={senderEmail} onChange={(e) => setSenderEmail(e.target.value)}/>
            <EmailSvg className="absolute top-1/2 transform -translate-y-1/2 left-2 text-primaryBlue"/>
        </label>
        <label htmlFor="" className="relative">
            <input
                className="shadow appearance-none border rounded w-full py-2 pl-8 pr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text" placeholder={t('formFields.phone')}
                value={senderPhone} onChange={(e) => setSenderPhone(e.target.value)}/>
            <PhoneSvg className="absolute top-1/2 transform -translate-y-1/2 left-2 text-primaryBlue"/>
        </label>
        <label htmlFor="" className="relative">
            <input
                className="shadow appearance-none border rounded w-full py-2 pl-8 pr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text" placeholder={t('formFields.subject')}
                value={senderSubject} onChange={(e) => setSenderSubject(e.target.value)}/>
            <SubjectSvg
                className="absolute top-1/2 transform -translate-y-1/2 left-2 text-primaryBlue"/>
        </label>
        <label htmlFor="" className="">
                        <textarea placeholder={t('formFields.message')}
                                  value={messageClient} onChange={(e) => setMessageClient(e.target.value)}
                                  className="h-40  shadow appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">

                        </textarea>
        </label>
        {error && <span className="text-red-500">{error}</span>}
        <button
            className="bg-primaryBlue hover:bg-sky-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center gap-1 justify-center"
            type="button"
            onClick={handleSubmit}
        >
            {loading &&
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                            stroke-width="4"></circle>
                    <path className="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>}
            Send
        </button>
    </form>

}

export default ContactForm;