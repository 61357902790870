import './App.css';
import HomePage from "./HomePage";
import './i18nConfig';
import Navbar from "./Navbar";
import Footer from "./Footer";
import ContactPage from "./Pages/ContactPage";
import TopArrow from "./components/TopArrow";
import {Routes, Route} from 'react-router-dom';
import SmoothScroll from "./components/SmoothScroll";
import NewOurAnalyses from "./Pages/NewOurAnalyses";
import NewAboutUs from "./Pages/NewAboutUs";


function App() {
    return (
        <div className="App">
            <Navbar/>

            <TopArrow toGo="up"/>
            <SmoothScroll>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/about-us" element={<NewAboutUs/>}/>
                    <Route path="/our-analyses" element={<NewOurAnalyses/>}/>
                </Routes>
            </SmoothScroll>
            <Footer/>
        </div>
    );
}

export default App;
