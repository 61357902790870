import {useTranslation} from "react-i18next";

const AboutUsContent = () => {
    const {t} = useTranslation();

    return (<div className="container max-w-screen-md mx-auto py-10 px-4">
        <p className=" text-neutral-700 text-3xl font-bold leading-[30px] py-4">{t('aboutUsNewPage.content.milestones.title')}</p>
        <p className="text-neutral-700 font-light"><span className="font-bold">2005:</span> {t('aboutUsNewPage.content.milestones.2005')}</p>
        <p className="text-neutral-700 font-light"><span className="font-bold">2010:</span> {t('aboutUsNewPage.content.milestones.2010')}</p>
        <p className="text-neutral-700 font-light"><span className="font-bold">2015:</span> {t('aboutUsNewPage.content.milestones.2015')}</p>
        <p className="text-neutral-700 font-light"><span className="font-bold">2020:</span> {t('aboutUsNewPage.content.milestones.2020')}</p>


        <p className=" text-neutral-700 text-3xl font-bold leading-[30px] py-4">{t('aboutUsNewPage.content.practicalApplications')}</p>
        <p className="text-neutral-700 font-light">{t('aboutUsNewPage.content.applicationsDescription')}</p>


        <p className=" text-neutral-700 text-3xl font-bold leading-[30px] py-4">{t('aboutUsNewPage.content.futureVision')}</p>
        <p className="text-neutral-700 font-light">
            {t('aboutUsNewPage.content.universeOfSkills.description')}
            <ul className="py-4">
                <li className="ml-4">- {t("aboutUsNewPage.content.universeOfSkills.requirements.1")}</li>
                <li className="ml-4">- {t("aboutUsNewPage.content.universeOfSkills.requirements.2")}</li>
                <li className="ml-4">- {t("aboutUsNewPage.content.universeOfSkills.requirements.3")}</li>
                <li className="ml-4">- {t("aboutUsNewPage.content.universeOfSkills.requirements.4")}</li>
            </ul>

            {t('aboutUsNewPage.content.universeOfSkills.conclusion')}
        </p>

    </div>)
}

export default AboutUsContent;