import FeaturedImage from "../components/generic/FeaturedImage";
import SimpleQuote from "../components/generic/SimpleQuote";
import bg from "./../images/newAboutBg.jpg";
import GetInTouch from "../GetInTouch";
import AboutUsContent from "../components/AboutUsContent";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import AboutUsVideo from "../components/generic/AboutUsVideo";


const NewAboutUs = () => {

    const {t} = useTranslation();

    return <div className="">
        <FeaturedImage title={t("aboutUsNewPage.header")} bg={bg}/>
        <SimpleQuote title={t("aboutUsNewPage.content.intro")} writer={'Tino Senoner'} quote={t("aboutUsNewPage.content.quote")}/>
        <AboutUsVideo/>
        <AboutUsContent/>
        <GetInTouch/>
    </div>
}
export default NewAboutUs