import contactImage from '../images/contactImage.png'
import {ReactComponent as EmailSvg} from '../images/svg/envelope.svg';
import {ReactComponent as PhoneSvg} from '../images/svg/telephone.svg';
import {ReactComponent as PinSvg} from '../images/svg/geo-alt.svg';
import {ReactComponent as SwitzerlandSvg} from '../images/svg/switzerland.svg';
import {useTranslation} from "react-i18next";
import ContactForm from "../components/ContactForm";

const ContactPage = () => {
    const {t} = useTranslation();

    return <>
        <div className="md:bg-primaryBlue relative">
            <div className="w-full h-1/2 absolute z-0 bg-white bottom-0"></div>
            <div className="container mx-auto p-4 flex flex-col md:flex-row md:pt-28 my-20 gap-4 justify-center">
                <div className="w-full md:w-1/2 relative">
                    <img src={contactImage} className="max-w-md mx-auto w-full" alt="Get in touch"/>
                    <div className="flex flex-col gap-10 max-w-md mx-auto ">
                        <p className="text-6xl font-black text-primaryBlue">{t('contact.getInTouch')}</p>
                        <div className="flex flex-col gap-4">
                            <div className="flex gap-x-2 items-center"><EmailSvg className="text-primaryBlue"/>support@dynaskills-dynajobs.com
                            </div>
                            <div className="flex gap-x-2 "><PinSvg
                                className="text-primaryBlue h-[27px]"/>{t('contact.address')} {t('contact.address2')}
                                <SwitzerlandSvg className="w-4 h-[27px]"/></div>
                            <div className="flex gap-x-2 items-center"><PhoneSvg className="text-primaryBlue"/>+41 079
                                240 39 01
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/2 relative">
                    <ContactForm/>
                </div>
            </div>
        </div>
    </>
}
export default ContactPage;